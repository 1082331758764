import { render, staticRenderFns } from "./AfterSalesDetail.vue?vue&type=template&id=2fb66320&scoped=true"
import script from "./AfterSalesDetail.vue?vue&type=script&lang=js"
export * from "./AfterSalesDetail.vue?vue&type=script&lang=js"
import style0 from "./AfterSalesDetail.vue?vue&type=style&index=0&id=2fb66320&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fb66320",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/Lmiy6ZAB/0/ifirst/imall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2fb66320')) {
      api.createRecord('2fb66320', component.options)
    } else {
      api.reload('2fb66320', component.options)
    }
    module.hot.accept("./AfterSalesDetail.vue?vue&type=template&id=2fb66320&scoped=true", function () {
      api.rerender('2fb66320', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall-manage/after-sales/AfterSalesDetail.vue"
export default component.exports